import React from "react";
import Lottie from "react-lottie";
import cat_anim from "./animations/kat.json";
import { SocialIcon } from "react-social-icons";
import "./App.css";

function App() {
  const lottieOpts = {
    loop: true,
    autoplay: true,
    animationData: cat_anim,
  };

  return (
    <div className="App">
      <header className="App-header">
        <Lottie options={lottieOpts} height={400} width={400} />
        {/*<img src={logo} className="App-logo" alt="logo" />*/}

        <p>Under Maintenance - <span> Brand new site coming soon!</span></p>
        <div style={{ justifyItems: "center" }} className="socials">
          <a href="https://github.com/jcernea" target="_blank" rel="noreferrer">
            <SocialIcon className="social" network="github" bgColor="#66FCF1" />
          </a>
          <a href="https://uk.linkedin.com/in/anthocer" target="_blank" rel="noreferrer">
            <SocialIcon className="social" network="linkedin" bgColor="#66FCF1" style={{ marginLeft: "20px" }} />
          </a>
        </div>
      </header>
    </div>
  );
}

export default App;
